<template>
    <div class="card fill-height">
        <div class="card-image is-horizontal-center">
            <figure class="image">
                <Cleaning v-if="imageName === 'cleaning'" />
                <Computer v-else-if="imageName === 'computer'" />
                <Archive v-else-if="imageName === 'archive'" />
            </figure>
            <div class="typography--body-bold has-ellipsis text-center">{{ title }}</div>
        </div>
        <div class="card-content">
            {{ content }}
        </div>
    </div>
</template>

<script>

    import Cleaning from '@/components/icons/Cleaning'
    import Computer from '@/components/icons/Computer'
    import Archive from '@/components/icons/Archive'

    export default {
        name: 'MarketingCard',
        components: {
            Cleaning,
            Computer,
            Archive
        },
        props: {
            title: { type: String, required: true },
            content: { type: String, required: true },
            imageName: {
                type: String,
                required: true,
                validator: function(value) {
                    return [ 'archive', 'computer', 'cleaning' ].includes(value)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~@/assets/vars.scss";

    .card {
        background-color: $lightest-grey;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }

    .has-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .text-center {
        text-align: center;
    }

    .card-image {
        padding: 20px;
        height: 131px;

        & > figure {
            width: 48px;
            margin: auto;
        }
    }

    .card-content {
        height: calc(100% - 131px);
        padding: 20px;
        background-color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>


