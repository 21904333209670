<template>
    <VisitorPage ref="page" :vertical-center="showmore!==2">
        <section id="landing-page">
            <template v-if="showmore!==2">
                <p class="typography--body-bold">{{ $t('Welcome') }}</p>
                <h1 class="typography--h1-bold mt-4 mb-12">{{ $t('HygieneControl') }}</h1>
                <p class="mb-12">
                    {{ $t('Landing Page intro') }}
                </p>

                <template v-if="showmore===1">
                    <div class="marketing-wrapper">
                        <h2 class="typography--h1-bold mt-4 mb-12">{{ $t('Landing Page Marketing Title') }}</h2>
                        <div style="display: flex; flex-direction: row;">
                            <div class="mr-2"
                                 style="flex: 1; min-width: calc(80% / 3); max-width: calc(100% / 3);">
                                <MarketingCard
                                    :title="$t('Hygieneconcept')"
                                    :content="$t('Hygieneconcept content')"
                                    image-name="cleaning"
                                />
                            </div>
                            <div class="ml-2 mr-2"
                                 style="flex: 1; min-width: calc(80% / 3); max-width: calc(100% / 3);">
                                <MarketingCard
                                    :title="$t('Archive')"
                                    :content="$t('Archive content')"
                                    image-name="archive"
                                    class=""
                                />
                            </div>
                            <div class="ml-2"
                                 style="flex: 1; min-width: calc(80% / 3); max-width: calc(100% / 3);">
                                <MarketingCard
                                    :title="$t('Digital / Analog')"
                                    :content="$t('Digital / Analog content')"
                                    image-name="computer"
                                />
                            </div>
                        </div>
                    </div>
                </template>

                <div :class="'buttons mt-8 ' + (showmore===0 ? ' mt-20 ' : '')">
                    <router-link :to="{ name:'login' }">
                        <b-button type="is-primary">
                            {{ $t('Sign in') }}
                        </b-button>
                    </router-link>
                    <template v-if="showmore===0">
                        <b-button class="ml-4" @click="setShowmore(1)">
                            {{ $t('Show more') }}
                        </b-button>
                    </template>
                </div>
            </template>
        </section>
    </VisitorPage>
</template>

<script>
    import MarketingCard from '@/modules/LandingPage/components/MarketingCard'
    import { languageOptions } from '@/modules/Registration/consts'
    import i18n from '@/config/i18n'
    import VisitorPage from '@/components/VisitorPage'

    export default {
        name: 'LandingPage',
        components: {
            MarketingCard,
            VisitorPage
        },
        data() {
            return {
                languageOptions: languageOptions,
                i18n: i18n,
                showmore: 0
            }
        },
        computed: {
            locales() {
                return languageOptions.map(o => ({ key: o.key.toLowerCase(), label: o.label }))
            }
        },
        methods: {
            setShowmore(index) {
                this.showmore = index
                this.$refs['page'].scrollMain(0)
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "~@/assets/vars";

</style>
